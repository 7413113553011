import { FeatureGuard } from 'components/featureGuard/FeatureGuard';
import { Feature } from 'utils/types/features';
import { AddNewButtonContained, ButtonContained, CancelButtonOutlined } from 'components/shared/button';
import { StyledBulkIcon } from 'pages/campaigns/campaignManagement/Campaigns.style';
import { ApprovalStatus, ApprovalStatusLabel, GenericItem } from 'utils/types';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import React from 'react';
import { NewStyledApproveButton, StyledRevokeButton } from 'pages/shared/shared.style';
import Tooltip from 'components/shared/tooltip/Tooltip';
import { hideTooltip } from 'utils/tooltip';
import { UserRole } from 'utils/types/users';
import { RoleGuard } from 'components/roleGuard/RoleGuard';
import { useSelector } from 'react-redux';
import { users } from 'app/slices/users';
import { OfferSource } from 'pages/offers/offerManagement/Offers.const';
import { CampaignBulkAction, CampaignBulkActionsProps } from './CampaignBulkActions.consts';
import { StyledButtonDropdown } from './CampaignBulkActions.style';

const CampaignBulkActions = ({
  bulkAction,
  offerSource,
  onBulkActionChange,
  onBulkActionSubmit,
  selectedCampaigns: { total, local, conflicted, notEligibleForSelfApproval, invalid },
  bulkUpdateInProgress,
}: CampaignBulkActionsProps) => {
  const { user } = useSelector(users);
  const validCampaigns = total - local - conflicted - notEligibleForSelfApproval - invalid;

  const onMenuActionSelect = (action: CampaignBulkAction) => {
    onBulkActionChange(action);
    let statusFilterValue: string;
    switch (action) {
      case CampaignBulkAction.BulkApprove:
        statusFilterValue = ApprovalStatusLabel[ApprovalStatus.PendingApproval];
        break;
      case CampaignBulkAction.BulkStopAssociation:
        statusFilterValue = ApprovalStatusLabel[ApprovalStatus.Active];
        break;
      case CampaignBulkAction.BulkSubmitForApproval:
        statusFilterValue = ApprovalStatusLabel[ApprovalStatus.Draft];
        break;
      case CampaignBulkAction.BulkRevoke:
        statusFilterValue = `${ApprovalStatusLabel[ApprovalStatus.Deployed]} and ${
          ApprovalStatusLabel[ApprovalStatus.Active]
        }`;
        break;
    }
    showToast(MessageType.Info, `A ${statusFilterValue} status filter has been applied`);
  };

  const generateSkippedCampaignsMessage = () => {
    const skippedCampaigns = [];
    local > 0 && skippedCampaigns.push(`${local} ${local > 1 ? 'are' : 'is'} local`);
    conflicted > 0 && skippedCampaigns.push(`${conflicted} ${conflicted > 1 ? 'are' : 'is'} conflicted`);
    notEligibleForSelfApproval > 0 && skippedCampaigns.push(`${notEligibleForSelfApproval} cannot be self-approved`);
    invalid > 0 && skippedCampaigns.push(`${invalid} ${local > 1 ? 'are' : 'is'} invalid`);
    if (skippedCampaigns.length > 0) {
      let skippedCampaignMessage = `.\n${skippedCampaigns.join(', ')}.\n`;
      skippedCampaignMessage += validCampaigns ? `${validCampaigns} campaigns will` : 'No campaign can';
      return skippedCampaignMessage;
    }
    return '';
  };

  const generateTooltipMessage = () => {
    let message = `${total} campaigns are selected`;
    if (local || conflicted || notEligibleForSelfApproval || invalid) {
      message += generateSkippedCampaignsMessage();
    } else {
      message += ` and will`;
    }

    if (bulkAction === CampaignBulkAction.BulkStopAssociation) {
      message += ' have association stopped';
    } else {
      message += ' be ';
      switch (bulkAction) {
        case CampaignBulkAction.BulkApprove:
          message += 'approved';
          break;
        case CampaignBulkAction.BulkRevoke:
          message += 'revoked';
          break;
        default:
          message += 'submitted for approval';
          break;
      }
    }

    if (validCampaigns) {
      message += `\nAre you sure?`;
    }
    return message;
  };

  const onCampaignStatusChangeClick = async (action: CampaignBulkAction) => {
    let actionDescription: string;

    try {
      switch (action) {
        case CampaignBulkAction.BulkApprove:
        case CampaignBulkAction.BulkSubmitForApproval: {
          actionDescription = action === CampaignBulkAction.BulkApprove ? 'approved' : 'submitted for approval';
          const {
            data: { bulkCampaignsStatusUpdate },
          } = (await onBulkActionSubmit()) as any;
          const toastMessage = action === CampaignBulkAction.BulkSubmitForApproval ? 'submission for ' : '';

          !bulkCampaignsStatusUpdate?.isProcessed
            ? showToast(
                MessageType.Info,
                `Your campaign ${toastMessage}approval request has been received, we are working on it, and you'll be notified once it's ready.`,
              )
            : showToast(MessageType.Success, `Campaigns successfully ${actionDescription}`);
          break;
        }

        case CampaignBulkAction.BulkRevoke:
          actionDescription = 'submitted for revoke';
          await onBulkActionSubmit(action);
          showToast(MessageType.Info, `Request for ${actionDescription} has been received. We are working on it.`);
          break;

        case CampaignBulkAction.BulkStopAssociation:
          actionDescription = 'submitted for stopping association';
          await onBulkActionSubmit(action);
          showToast(MessageType.Info, `Request for ${actionDescription} has been received. We are working on it.`);
          break;

        default:
          throw new Error(`Unknown action type: ${action}`);
      }
    } catch (error) {
      const errorMessage = error?.message?.includes('Failed to Bulk Approve')
        ? 'One or more campaigns not deployed successfully.'
        : `Campaigns failed to be ${actionDescription || 'updated'}.`;

      showToast(MessageType.Error, errorMessage);
    } finally {
      onBulkActionChange(null); // Ensure this is always called
    }
  };

  const items =
    offerSource === OfferSource.VCE
      ? [
          {
            id: CampaignBulkAction.BulkSubmitForApproval,
            name: CampaignBulkAction.BulkSubmitForApproval,
            acceptedRoles: [UserRole.Trainee, UserRole.Creator, UserRole.Admin, UserRole.SysAdmin],
          },
          {
            id: CampaignBulkAction.BulkApprove,
            name: CampaignBulkAction.BulkApprove,
            acceptedRoles: [UserRole.Creator, UserRole.Admin, UserRole.SysAdmin],
          },
        ]
      : [
          {
            id: CampaignBulkAction.BulkStopAssociation,
            name: CampaignBulkAction.BulkStopAssociation,
            acceptedRoles: [UserRole.Admin, UserRole.SysAdmin],
          },
          {
            id: CampaignBulkAction.BulkRevoke,
            name: CampaignBulkAction.BulkRevoke,
            acceptedRoles: [UserRole.Admin, UserRole.SysAdmin],
          },
        ].filter((item) => item.acceptedRoles.includes(user.role));

  return (
    <FeatureGuard features={[{ feature: Feature.CampaignBulkActions }]}>
      {bulkAction && (
        <CancelButtonOutlined data-automation-id="cancel-button" onClick={() => onBulkActionChange(null)}>
          Cancel
        </CancelButtonOutlined>
      )}
      {bulkAction === CampaignBulkAction.BulkApprove && (
        <>
          <NewStyledApproveButton
            data-for="approve-tooltip"
            data-automation-id="approve-button"
            data-tip
            disabled={bulkUpdateInProgress || total === 0}
            onClick={() => null}
          >
            Approve
          </NewStyledApproveButton>
          <Tooltip
            id="approve-tooltip"
            content={generateTooltipMessage()}
            onDisapproveClick={() => {
              hideTooltip('#approve-tooltip');
            }}
            approveMsg={validCampaigns ? 'Yes' : ''}
            disapproveMsg={validCampaigns ? 'No' : 'OK'}
            onApproveClick={() => {
              hideTooltip('#approve-tooltip');
              onCampaignStatusChangeClick(CampaignBulkAction.BulkApprove);
            }}
          />
        </>
      )}
      {bulkAction === CampaignBulkAction.BulkSubmitForApproval && (
        <>
          <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
            <AddNewButtonContained
              data-for="submit-for-approval-tooltip"
              data-automation-id="submit-for-approval-button"
              data-tip
              onClick={() => null}
              disabled={bulkUpdateInProgress || total === 0}
            >
              Submit for Approval
            </AddNewButtonContained>
          </RoleGuard>
          <Tooltip
            id="submit-for-approval-tooltip"
            content={generateTooltipMessage()}
            onDisapproveClick={() => {
              hideTooltip('#submit-for-approval-tooltip');
            }}
            approveMsg={validCampaigns ? 'Yes' : ''}
            disapproveMsg={validCampaigns ? 'No' : 'OK'}
            onApproveClick={() => {
              hideTooltip('#submit-for-approval-tooltip');
              onCampaignStatusChangeClick(CampaignBulkAction.BulkSubmitForApproval);
            }}
          />
        </>
      )}
      {bulkAction === CampaignBulkAction.BulkStopAssociation && (
        <>
          <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin]}>
            <ButtonContained
              data-for="stop-association-tooltip"
              data-automation-id="stop-association-button"
              data-tip
              onClick={() => null}
              disabled={bulkUpdateInProgress || total === 0}
            >
              Stop Association
            </ButtonContained>
          </RoleGuard>
          <Tooltip
            id="stop-association-tooltip"
            content={generateTooltipMessage()}
            onDisapproveClick={() => {
              hideTooltip('#stop-association-tooltip');
            }}
            approveMsg={validCampaigns ? 'Yes, Stop Association' : ''}
            disapproveMsg={validCampaigns ? 'No' : 'OK'}
            onApproveClick={() => {
              hideTooltip('#stop-association-tooltip');
              onCampaignStatusChangeClick(CampaignBulkAction.BulkStopAssociation);
            }}
          />
        </>
      )}
      {bulkAction === CampaignBulkAction.BulkRevoke && (
        <>
          <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin]}>
            <StyledRevokeButton
              data-for="revoke-tooltip"
              data-automation-id="revoke-button"
              data-tip
              disabled={bulkUpdateInProgress || total === 0}
              onClick={() => null}
            >
              Revoke
            </StyledRevokeButton>
          </RoleGuard>
          <Tooltip
            id="revoke-tooltip"
            content={generateTooltipMessage()}
            onDisapproveClick={() => {
              hideTooltip('#revoke-tooltip');
            }}
            approveMsg={validCampaigns ? 'Yes, Revoke' : ''}
            disapproveMsg={validCampaigns ? 'No' : 'OK'}
            onApproveClick={() => {
              hideTooltip('#revoke-tooltip');
              onCampaignStatusChangeClick(CampaignBulkAction.BulkRevoke);
            }}
          />
        </>
      )}
      {!bulkAction && (
        <StyledButtonDropdown
          items={items}
          onItemClick={(action: GenericItem) => onMenuActionSelect(action.id as CampaignBulkAction)}
          position="bottom-left"
          data-automation-id="bulk-actions-dropdown"
        >
          <StyledBulkIcon name="factCheck" />
        </StyledButtonDropdown>
      )}
    </FeatureGuard>
  );
};
export default CampaignBulkActions;
